<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">分享好友赢奖励</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ stu == "edit" ? "修改" : "创建" }}活动</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item label="活动名称：" prop="shareName">
                <el-input
                  size="small"
                  placeholder="请输入活动名称"
                  v-model="ruleForm.shareName"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item
                label="活动简介："
                class="form-item form-textarea form-control"
                prop="shareProfile"
              >
                <el-input
                  size="small"
                  placeholder="请输入活动简介"
                  v-model="ruleForm.shareProfile"
                  maxlength="30"
                  show-word-limit
                ></el-input>
                <p>
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img src="../../assets/activeSet4.png" alt="" />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="活动日期："
                class="form-item form-textarea"
                required
              >
                <div style="display:flex;align-items:center">
                  <el-form-item class="form-itemDate" prop="startTime">
                    <el-date-picker
                      v-model="ruleForm.startTime"
                      type="datetime"
                      :disabled="activeState == 20 || activeState == 30"
                      size="small"
                      placeholder="选择开始日期"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </el-form-item>
                  至
                  <el-form-item class="form-itemDate" prop="endTime">
                    <el-date-picker
                      v-model="ruleForm.endTime"
                      type="datetime"
                      size="small"
                      placeholder="选择结束日期"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :picker-options="endDatePicker"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item
                label="分享积分："
                class="form-item"
                prop="shareIntegration"
              >
                <el-input
                  placeholder="请输入分享积分"
                  size="small"
                  v-model="ruleForm.shareIntegration"
                  :disabled="activeState == 20 || activeState == 30"
                  onkeyup="value=value.replace(/\D|^0/g,'')"
                  maxlength="5"
                  max="10000"
                  class="input-with-select"
                >
                  <span slot="append">积分/次</span>
                </el-input>
              </el-form-item>
              <el-form-item
                label="每日积分上限："
                class="form-item"
                prop="maxIntegration"
              >
                <el-input
                  placeholder="请输入每日积分上限"
                  size="small"
                  v-model="ruleForm.maxIntegration"
                  maxlength="5"
                  max="10000"
                  onkeyup="value=value.replace(/\D|^0/g,'')"
                  class="input-with-select"
                >
                  <span slot="append">积分</span>
                </el-input>
                <p>超过该积分后,当天不再获得积分</p>
              </el-form-item>
              <el-form-item
                label="内容链接："
                class="form-item"
                prop="sharePath"
              >
                <el-input
                  placeholder="请输入内容链接"
                  size="small"
                   type="url"
                  :disabled="activeState == 20 || activeState == 30"
                  v-model="ruleForm.sharePath"
                  class="input-with-select"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label="活动范围："
                class="form-item form-textarea"
                prop="shareArea"
              >
                <el-cascader
                  v-model="ruleForm.shareArea"
                  :disabled="activeState == 20 || activeState == 30"
                  placeholder="请选择活动范围"
                  :options="areatreeList"
                  :props="propsarea"
                  size="small"
                  @change="handleChange"
                ></el-cascader>
                <p>只有定位在该区域的用户才能看到该活动。</p>
              </el-form-item>
              <el-form-item
                label="是否上架："
                class="form-item form-textarea"
                prop="validState"
              >
                <el-select
                  v-model="ruleForm.validState"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in shelfList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="activeSetbtn">
              <el-button class="bgc-bv" @click="doCancel">取消</el-button>
              <el-button class="bgc-bv" @click="doSure">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "CreateShareActivity",
  components: {},
  mixins: [List],
  data() {
    let validates = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入活动日期"));
      } else if (value == this.ruleForm.startTime) {
        callback(new Error("活动结束时间不能和活动开始时间相同"));
      } else {
        callback();
      }
    };
    let validateUrl = (rule, value, callback) => {
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!value) {
        callback(new Error("请输入内容链接"));
      } else if (value && !reg.test(value)) {
        callback(new Error("请输入带有http或https的正确链接地址"));
      } else {
        callback();
      }
    };
    let validateshareIntegration = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入分享积分"));
      } else if (value>10000) {
        callback(new Error("请输入小于10000的分享积分"));
      } else {
        callback();
      }
    };
    let validatemaxIntegration = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入每日积分上限"));
      } else if (value>10000) {
        callback(new Error("请输入小于10000的有效的每日积分上限"));
      } else {
        callback();
      }
    };
    return {
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
        multiple: true,
      },
      shelfList: [
        {
          value: true,
          label: "上架",
        },
        {
          value: false,
          label: "下架",
        },
      ],
      ruleForm: {
        shareName: "", //活动名称
        shareProfile: "", //活动简介
        startTime: "",
        endTime: "", //活动日期
        shareIntegration: "", //分享积分
        maxIntegration: "", //每日上限
        sharePath: "", //内容链接
        shareArea: [], //活动范围
        validState: false,
      },
      rules: {
        shareName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        shareProfile: [
          { required: true, message: "请输入活动简介", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束日期", trigger: "change" },
          { required: true, validator: validates, trigger: "change" },
        ],
        shareIntegration: [
          { required: true, message: "请输入分享积分", trigger: "blur" },
           { required: true, validator: validateshareIntegration, trigger: "blur" },
        ],
        maxIntegration: [
          { required: true, message: "请输入每日积分上限", trigger: "blur" },
           { required: true, validator: validatemaxIntegration, trigger: "blur" },
        ],
        sharePath: [
          { required: true, message: "请输入内容链接", trigger: "blur" },
           { required: true, validator: validateUrl, trigger: "blur" },
        ],
        shareArea: [
          { required: true, message: "请选择活动范围", trigger: "change" },
        ],
        validState: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
      },
      endDatePicker: this.processDate(),
      disableds: false,
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.activeState = this.$route.query.activeState;
    if (this.stu == "edit") {
      this.shareId = this.$route.query.shareId;
      this.getDetails(this.shareId);
    }
    this.getareatree();
  },
  computed: {},
  methods: {
    beginDate() {
      let self = this;
      return {
        disabledDate(time) {
          return time.getTime() > Date.now(); //开始时间不选时，结束时间最大值小于等于当天
        },
      };
    },
    //提出结束时间必须大于提出开始时间
    processDate() {
      let self = this;
      return {
        disabledDate(time) {
          if (self.ruleForm.startTime) {
            return (
              new Date(self.ruleForm.startTime).getTime() > time.getTime() ||
              time.getTime() >= self.ruleForm.startTime
            );
          } else {
            return time.getTime() >= self.ruleForm.startTime; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=2").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          el.disabled = false;
        });

        this.areatreeList =
          [{ label: "全部区域", value: "all", disabled: false }, ...data] || [];
      });
    },
    handleChange(val) {
      let self = this;
      if (val.length > 0) {
        val.map((element) => {
          console.log(element);
          if (element == "") {
            self.areatreeList.map((el) => {
              if (el.value != "") {
                el.disabled = true;
              } else {
                el.disabled = false;
              }
            });
          } else {
            self.areatreeList.map((el) => {
              if (el.value == "") {
                el.disabled = true;
              }
            });
          }
        });
      } else {
        self.areatreeList.map((el) => {
          el.disabled = false;
        });
      }
    },
    getDetails(shareId) {
      this.$post(
        "/share/act-share/query",
        {
          shareId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
           let shareArea = '';
          if(ret.data.shareArea == '') {
             shareArea = 'all'
          }
          this.ruleForm = {
            ...this.ruleForm,
            ...ret.data,
            startTime: ret.data.startTime.replaceAll("/", "-"),
            endTime: ret.data.endTime.replaceAll("/", "-"),
            shareArea: ret.data.shareArea == '' ? shareArea.split(",") : ret.data.shareArea.split(",") ,
          };
          console.log(this.ruleForm.shareArea);
          this.ruleForm.shareArea.map((els) => {
            console.log(els);
            if (els == "all") {
              console.log('5555');
              this.areatreeList.map((el) => {
                console.log(el);
                if (el.value == "all") {
                  el.disabled = false;
                } else {
                  el.disabled = true;
                }
              });
            } else {
            this.areatreeList.map((el) => {
              if (el.value == "all") {
                el.disabled = true;
              }
            });
          } 
          });
        })
        .catch((err) => {
          return;
        });
    },
    /* 确定 */
    doSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let paramr = {
            shareName: this.ruleForm.shareName,
            shareProfile: this.ruleForm.shareProfile,
            startTime: this.ruleForm.startTime,
            endTime: this.ruleForm.endTime,
            shareIntegration: this.ruleForm.shareIntegration,
            maxIntegration: this.ruleForm.maxIntegration,
            sharePath: this.ruleForm.sharePath,
            shareArea: this.ruleForm.shareArea.toString() == 'all' ? '':this.ruleForm.shareArea.toString(),
            validState: this.ruleForm.validState,
          };
          if (this.shareId) {
            paramr.shareId = this.shareId;
          }
          this.$post(
            this.stu != "edit"
              ? "/share/act-share/insert"
              : "/share/act-share/modify",
            paramr,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: this.stu == "edit" ? "修改成功" : "创建成功",
                  type: "success",
                });
                this.$router.push({
                  path: "/web/ShareFriendsToWinAwardsList",
                  query: {
                    refresh: true,
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/ShareFriendsToWinAwardsList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
.integral {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: center;
  .integralTitle {
    min-width: 4rem;
  }
  > div:nth-child(3) {
    padding-left: 1.5rem;
  }
}
// .el-form-item {
//   display: flex;
//   align-items: center;
//   .el-form-item__content {
//     width: 100%;
//     margin-left: 0 !important;
//   }
// }
.form-control {
  align-items: flex-start;
}
.form-integral {
  align-items: flex-start;
  .form-integralbox {
    padding-bottom: 0.75rem;
  }
}
.form-itemDate {
  width: 50%;
}
</style>
<style lang="less">
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>
